.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #1c1c1c;
}

.securitysince-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px 0px;
}

.securitysince-1 img {
  width: 30%;
}

.securitysince-1 span2 {
  color: white;
  font-weight: 600;
  font-size: 22px;
  margin-top: 10px;
}

.securitysince-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  margin: 40px 0px;
  gap: 20px;
  width: 100%;
}

.links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.link {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.links span1 {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}

.links a {
  text-decoration: none;
  color: #4c4b4b;
  margin: 10px 0px;
}

.links a:hover {
  color: #ffff;
}

.securitysince-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4c4b4b;
  height: 2px;
  width: 90%;
}

.securitysince-4 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  margin: 40px 0px;
  width: 100%;
}

.map {
  width: 70%;
}

.securitygrid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
}

.securitysince-4 span3 {
  color: #fff;
  font-weight: 600;
  font-size: 22px;
  text-transform: uppercase;
}

.securitysince-4 p1 {
  text-decoration: none;
  color: #4c4b4b;
  margin: 10px 0px;
}

.securitysince-5 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  margin: 20px 0px;
}

.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* flex: 1; */
}

.copyright span1 {
  color: #4c4b4b;
  font-weight: 700;
}

.socialtags {
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialtags a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin: 0px 10px;
  color: white;
  padding: 8px;
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.terms {
  display: flex;
  justify-content: center;
  align-items: center;
}

.terms a {
  text-decoration: none;
  margin: 0px 10px;
  color: white;
  font-weight: 500;
}

@media screen and (max-width: 850px) {
  .securitysince-4 {
    grid-template-columns: repeat(1, 1fr);
  }
  .map{
    margin: 10px 0px 0px 0px;
  }
}

@media screen and (max-width: 615px) {
  .securitysince-2 {
    grid-template-columns: repeat(1, 1fr);
  }
  .links{
    align-items: flex-start;
    margin-left: 20px;
  }
}

@media screen and (max-width: 450px) {
  .securitysince-5{
    grid-template-columns: repeat(1, 1fr);
  }
  .copyright{
    margin: 0px 0px 10px 0px;
  }
}