.add2 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    height: 550px;
    margin: 10px 20px;
    border-radius: 2px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  @media screen and (max-width: 900px) {
    .add2 {
      height: 600px;
    }
  }
  
  @media screen and (max-width: 765px) {
    .add2 {
      height: 650px;
    }
  }
  
 @media screen and (max-width: 667px) {
    .add2 {
      height: 700px;
    }
  }

 @media screen and (max-width: 616px) {
    .add2 {
      height: 600px;
    }
  }
 @media screen and (max-width: 390px) {
    .add2 {
      height: 650px;
    }
  }