.training{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.training1{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
    width: 100%;
    background-color: #dfdddd;
}

.basictraining{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0px 40px;
}

.basictraining span{
    font-size: 40px;
    color: red;
    font-weight: 700;
    margin-bottom: 30px;
    position: relative;
    right: 90px;
}

.basictraining p{
    font-weight: 300;
}

.basictrainimg{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 20px;
}

.basictrainimg img{
    height: 480px;
}

.training2{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 900px;
}

.bstoption{
    display: flex;
    justify-content: center;
    align-items: center;
}

.bstoption span{
    font-size: 40px;
    font-weight: 700;
    color: red;
    margin-bottom: 90px;
}

.ontarioclass{
    display: flex;
    justify-content: center;
    align-items: center;
}

.classgrid{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 60px;
}

.classgrid span1{
    font-size: 35px;
    font-weight: 700;
    color: red;
    margin-bottom: 30px;
}

.classgrid p{
    font-weight: 300;
}

.classimg img{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.classgrid a{
    text-decoration: none;
    color: #fff;
    background-color: red;
    padding: 18px ;
    font-weight: 600;
    text-transform: capitalize;
}

.classgrid a:hover{
    background-color: orange;
}

.training3{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
}

.onlineclass{
    display: flex;
    justify-content: center;
    align-items:flex-start;
    flex-direction: column;
    margin-right: 80px;
}

.onlineclass span1{
    font-size: 35px;
    font-weight: 700;
    color: red;
    margin-bottom: 30px;
}

.onlineclass p{
    font-weight: 300;
}

.onlineclass a{
    text-decoration: none;
    color: #fff;
    background-color: red;
    padding: 18px ;
    font-weight: 600;
    text-transform: capitalize;
}

.onlineclass a:hover{
    background-color: orange;
}

.onlineclass img{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.training4{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
}

.firstaidimg img{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.firstaidgrid{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 60px;
}

.firstaidgrid span{
    font-size: 35px;
    font-weight: 700;
    color: red;
}

.firstaidgrid p{
    font-weight: 300;
}

.training5{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 650px;
}

.ministrygrid{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 90px;
}

.ministrygrid span{
    font-size: 35px;
    font-weight: 700;
    color: red;
}

.ministrygrid p{
    font-weight: 300;
    margin-top: 30px;
}

.ministrygrid img{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.training6{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
    background-color: #dfdddd;
    width: 100%;
}

.oboardinggrid{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.oboardinggrid span{
    font-size: 35px;
    font-weight: 700;
    color: red;
    margin-bottom: 20px;
}

.oboardingimg{
    margin-right: 80px;
}

.oboardingimg img{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.oboardinggrid p{
    font-weight: 300;
}

.training7{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
}

.specialized{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 60px;
}

.specialized span{
    font-size: 35px;
    font-weight: 700;
    color: red;
    margin-bottom: 20px;
}

.specialized p{
    font-weight: 300;
}

.specializedimg img{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media screen and (max-width: 1590px) {
    .training1{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 700px;
        width: 100%;
        background-color: #dfdddd;
    }
    
    .basictraining{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0px 40px;
    }
    
    .basictraining span{
        font-size: 35px;
        color: red;
        font-weight: 700;
        margin-bottom: 30px;
    }
    
    .basictraining p{
        font-weight: 300;
        width: 90%;
    }
    
    .basictrainimg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 20px;
    }
    
    .basictrainimg img{
        height: 440px;
    }
}

@media screen and (max-width: 1475px) {
    .training1{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 700px;
        width: 100%;
        background-color: #dfdddd;
    }
    
    .basictraining{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0px 40px;
    }
    
    .basictraining span{
        font-size: 35px;
        color: red;
        font-weight: 700;
        margin-bottom: 30px;
    }
    
    .basictraining p{
        font-weight: 300;
        width: 90%;
        font-size: 15px;
    }
    
    .basictrainimg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 20px;
    }
    
    .basictrainimg img{
        height: 400px;
    }
}

@media screen and (max-width: 1400px) {
    .training1{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 700px;
        width: 100%;
        background-color: #dfdddd;
    }
    
    .basictraining{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0px 40px;
    }
    
    .basictraining span{
        font-size: 30px;
        color: red;
        font-weight: 700;
        margin-bottom: 20px;
        position: relative;
        right: 10px;
    }
    
    .basictraining p{
        font-weight: 300;
        width: 70%;
        font-size: 13px;
    }
    
    .basictrainimg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 20px;
    }
    
    .basictrainimg img{
        height: 350px;
    }
    .training2{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 900px;
    }
    
    .bstoption{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .bstoption span{
        font-size: 35px;
        font-weight: 700;
        color: red;
        margin-bottom: 90px;
    }
    
    .ontarioclass{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .classgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 60px;
    }
    
    .classgrid span1{
        font-size: 20px;
        font-weight: 700;
        color: red;
        margin-bottom: 30px;
    }
    
    .classgrid p{
        font-weight: 300;
        width: 90%;
        font-size: 14px;
    }
    
    .classimg img{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        height: 350px;
    }
    
    .classgrid a{
        text-decoration: none;
        color: #fff;
        background-color: red;
        padding: 13px ;
        font-weight: 600;
        font-size: 13px;
        text-transform: capitalize;
    }
    .training3{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 600px;
    }
    
    .onlineclass{
        display: flex;
        justify-content: center;
        align-items:flex-start;
        flex-direction: column;
        margin-right: 80px;
    }
    
    .onlineclass span1{
        font-size: 25px;
        font-weight: 700;
        color: red;
        margin-bottom: 30px;
    }
    
    .onlineclass p{
        font-weight: 300;
        font-size: 13px;
        width: 85%;
    }
    
    .onlineclass a{
        text-decoration: none;
        color: #fff;
        background-color: red;
        padding: 13px ;
        font-weight: 600;
        font-size: 13px;
        text-transform: capitalize;
    }
    .training4{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 600px;
    }
    .onlineimg img{
        height: 350px;
    }
    
    .firstaidimg img{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        height: 320px;
    }
    
    .firstaidgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 60px;
    }
    
    .firstaidgrid span{
        font-size: 30px;
        font-weight: 700;
        color: red;
    }
    
    .firstaidgrid p{
        font-weight: 300;
        font-size: 13px;
        width: 85%;
    }
    
    .training5{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 650px;
    }
    
    .ministrygrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-right: 90px;
    }

    .ministryimg img{
        height: 350px;
    }
    
    .ministrygrid span{
        font-size: 25px;
        font-weight: 700;
        color: red;
    }
    
    .ministrygrid p{
        font-weight: 300;
        margin-top: 30px;
        font-size: 13px;
        width: 85%;
    }
    .training6{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 700px;
        background-color: #dfdddd;
        width: 100%;
    }
    
    .oboardinggrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .oboardinggrid span{
        font-size: 25px;
        font-weight: 700;
        color: red;
        margin-bottom: 20px;
    }
    
    .oboardingimg{
        margin-right: 80px;
    }
    
    .oboardingimg img{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        height: 320px;
    }
    
    .oboardinggrid p{
        font-weight: 300;
        font-size: 13px;
        width: 85%;
    }
    
    .training7{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 700px;
    }
    
    .specialized{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-right: 60px;
    }
    .specializedimg img{
        height: 320px;
    }

    .specialized span{
        font-size: 25px;
        font-weight: 700;
        color: red;
        margin-bottom: 20px;
    }
    
    .specialized p{
        font-weight: 300;
        font-size: 13px;
        width: 85%;
    }
}

@media screen and (max-width: 1215px) {
    .training1{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 600px;
        width: 100%;
        background-color: #dfdddd;
    }
    
    .basictraining{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0px 40px;
    }
    
    .basictraining span{
        font-size: 25px;
        color: red;
        font-weight: 700;
        margin-bottom: 20px;
        position: relative;
        left: -40px;
    }
    
    .basictraining p{
        font-weight: 300;
        width: 70%;
        font-size: 13px;
    }
    
    .basictrainimg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 20px;
    }
    
    .basictrainimg img{
        height: 300px;
    }
    .training2{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 750px;
    }
    
    .bstoption{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .bstoption span{
        font-size: 25px;
        font-weight: 700;
        color: red;
        margin-bottom: 90px;
    }
    
    .ontarioclass{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .classgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 60px;
    }
    
    .classgrid span1{
        font-size: 18px;
        font-weight: 700;
        color: red;
        margin-bottom: 30px;
    }
    
    .classgrid p{
        font-weight: 300;
        width: 80%;
        font-size: 14px;
    }
    
    .classimg img{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        height: 300px;
    }
    
    .classgrid a{
        text-decoration: none;
        color: #fff;
        background-color: red;
        padding: 13px ;
        font-weight: 600;
        font-size: 12px;
        text-transform: capitalize;
    }
    .training3{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 600px;
    }
    
    .onlineclass{
        display: flex;
        justify-content: center;
        align-items:flex-start;
        flex-direction: column;
        margin-right: 80px;
    }
    
    .onlineclass span1{
        font-size: 20px;
        font-weight: 700;
        color: red;
        margin-bottom: 30px;
    }
    
    .onlineclass p{
        font-weight: 300;
        font-size: 13px;
        width: 85%;
    }
    
    .onlineclass a{
        text-decoration: none;
        color: #fff;
        background-color: red;
        padding: 13px ;
        font-weight: 600;
        font-size: 12px;
        text-transform: capitalize;
    }
    .training4{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 600px;
    }
    .onlineimg img{
        height: 300px;
    }
    
    .firstaidimg img{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        height: 320px;
    }
    
    .firstaidgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 60px;
    }
    
    .firstaidgrid span{
        font-size: 30px;
        font-weight: 700;
        color: red;
    }
    
    .firstaidgrid p{
        font-weight: 300;
        font-size: 13px;
        width: 85%;
    }
    
    .training5{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 650px;
    }
    
    .ministrygrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-right: 90px;
    }

    .ministryimg img{
        height: 300px;
    }
    
    .ministrygrid span{
        font-size: 25px;
        font-weight: 700;
        color: red;
    }
    
    .ministrygrid p{
        font-weight: 300;
        margin-top: 30px;
        font-size: 13px;
        width: 85%;
    }
    .training6{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 700px;
        background-color: #dfdddd;
        width: 100%;
    }
    
    .oboardinggrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .oboardinggrid span{
        font-size: 25px;
        font-weight: 700;
        color: red;
        margin-bottom: 20px;
    }
    
    .oboardingimg{
        margin-right: 80px;
    }
    
    .oboardingimg img{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        height: 300px;
    }
    
    .oboardinggrid p{
        font-weight: 300;
        font-size: 13px;
        width: 85%;
    }
    
    .training7{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 650px;
    }
    
    .specialized{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-right: 60px;
    }
    .specializedimg img{
        height: 320px;
    }

    .specialized span{
        font-size: 25px;
        font-weight: 700;
        color: red;
        margin-bottom: 20px;
    }
    
    .specialized p{
        font-weight: 300;
        font-size: 13px;
        width: 85%;
    }
}

@media screen and (max-width: 1160px) {
    .training1{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 700px;
        width: 100%;
        background-color: #dfdddd;
    }
    .ontarioclass{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .training2{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 890px;
    }
    .training3{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 850px;
    }
    .training4{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 650px;
    }
    .training5{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 650px;
    }
    .training6{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 700px;
        background-color: #dfdddd;
        width: 100%;
    }
    .training7{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 800px;
    }
}

@media screen and (max-width: 635px) {
    .basictraining{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0px 0px;
    }
    
    .basictraining span{
        font-size: 20px;
        color: red;
        font-weight: 700;
        margin-bottom: 20px;
        position: relative;
        left: -40px;
    }
    
    .basictraining p{
        font-weight: 300;
        width: 60%;
        font-size: 13px;
    }
    
    .basictrainimg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 20px;
    }
    
    .basictrainimg img{
        height: 250px;
    }
    
    .bstoption{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .bstoption span{
        font-size: 25px;
        font-weight: 700;
        color: red;
        margin-bottom: 90px;
    }
    
    .ontarioclass{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .classgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 60px;
    }
    
    .classgrid span1{
        font-size: 16px;
        font-weight: 700;
        color: red;
        margin-bottom: 30px;
    }
    
    .classgrid p{
        font-weight: 300;
        width: 70%;
        font-size: 14px;
    }
    
    .classimg img{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        height: 250px;
    }
    
    .classgrid a{
        text-decoration: none;
        color: #fff;
        background-color: red;
        padding: 10px ;
        font-weight: 600;
        font-size: 11px;
        text-transform: capitalize;
    }
    
    .onlineclass{
        display: flex;
        justify-content: center;
        align-items:flex-start;
        flex-direction: column;
        position: relative;
        left: 50px;
    }
    
    .onlineclass span1{
        font-size: 16px;
        font-weight: 700;
        color: red;
        margin-bottom: 30px;
    }
    
    .onlineclass p{
        font-weight: 300;
        font-size: 12px;
        width: 75%;
    }
    
    .onlineclass a{
        text-decoration: none;
        color: #fff;
        background-color: red;
        padding: 13px ;
        font-weight: 600;
        font-size: 12px;
        text-transform: capitalize;
    }
    .onlineimg img{
        height: 250px;
    }
    
    .firstaidimg img{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        height: 270px;
    }
    
    .firstaidgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 60px;
    }
    
    .firstaidgrid span{
        font-size: 25px;
        font-weight: 700;
        color: red;
    }
    
    .firstaidgrid p{
        font-weight: 300;
        font-size: 13px;
        width: 75%;
    }
    
    
    .ministrygrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        position: relative;
        left: 50px;
    }

    .ministryimg img{
        height: 250px;
    }
    
    .ministrygrid span{
        font-size: 25px;
        font-weight: 700;
        color: red;
    }
    
    .ministrygrid p{
        font-weight: 300;
        margin-top: 30px;
        font-size: 13px;
        width: 85%;
    }
    
    .oboardinggrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .oboardinggrid span{
        font-size: 20px;
        font-weight: 700;
        color: red;
        margin-bottom: 20px;
    }
    
    .oboardingimg{
        margin-right: 50px;
    }
    
    .oboardingimg img{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        height: 250px;
    }
    
    .oboardinggrid p{
        font-weight: 300;
        font-size: 13px;
        width: 75%;
    }
    
    
    .specialized{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-right: 0px;
    }
    .specializedimg img{
        height: 270px;
    }

    .specialized span{
        font-size: 20px;
        font-weight: 700;
        color: red;
        margin-bottom: 20px;
    }
    
    .specialized p{
        font-weight: 300;
        font-size: 13px;
        width: 75%;
    }
}


@media screen and (max-width: 495px) {
    .basictraining{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0px 0px;
    }
    
    .basictraining span{
        font-size: 20px;
        color: red;
        font-weight: 700;
        margin-bottom: 20px;
        position: relative;
        left: -40px;
    }
    
    .basictraining p{
        font-weight: 300;
        width: 60%;
        font-size: 13px;
    }
    
    .basictrainimg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 20px;
    }
    
    .basictrainimg img{
        height: 200px;
    }
    
    .bstoption{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .bstoption span{
        font-size: 25px;
        font-weight: 700;
        color: red;
        margin-bottom: 90px;
    }
    
    .ontarioclass{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .classgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 60px;
    }
    
    .classgrid span1{
        font-size: 16px;
        font-weight: 700;
        color: red;
        margin-bottom: 30px;
    }
    
    .classgrid p{
        font-weight: 300;
        width: 70%;
        font-size: 14px;
    }
    
    .classimg img{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        height: 200px;
    }
    
    .classgrid a{
        text-decoration: none;
        color: #fff;
        background-color: red;
        padding: 10px ;
        font-weight: 600;
        font-size: 11px;
        text-transform: capitalize;
    }
    
    .onlineclass{
        display: flex;
        justify-content: center;
        align-items:flex-start;
        flex-direction: column;
        position: relative;
        left: 50px;
    }
    
    .onlineclass span1{
        font-size: 16px;
        font-weight: 700;
        color: red;
        margin-bottom: 30px;
    }
    
    .onlineclass p{
        font-weight: 300;
        font-size: 12px;
        width: 75%;
    }
    
    .onlineclass a{
        text-decoration: none;
        color: #fff;
        background-color: red;
        padding: 13px ;
        font-weight: 600;
        font-size: 12px;
        text-transform: capitalize;
    }
    .onlineimg img{
        height: 220px;
    }
    
    .firstaidimg img{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        height: 210px;
    }
    
    .firstaidgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 60px;
    }
    
    .firstaidgrid span{
        font-size: 25px;
        font-weight: 700;
        color: red;
    }
    
    .firstaidgrid p{
        font-weight: 300;
        font-size: 13px;
        width: 75%;
    }
    
    
    .ministrygrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        position: relative;
        left: 50px;
    }

    .ministryimg img{
        height: 220px;
    }
    
    .ministrygrid span{
        font-size: 25px;
        font-weight: 700;
        color: red;
    }
    
    .ministrygrid p{
        font-weight: 300;
        margin-top: 30px;
        font-size: 13px;
        width: 85%;
    }
    
    .oboardinggrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 73%;
    }
    
    .oboardinggrid span{
        font-size: 20px;
        font-weight: 700;
        color: red;
        margin-bottom: 20px;
    }
    
    .oboardingimg{
        margin-right: 0px;
    }
    
    .oboardingimg img{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        height: 200px;
    }
    
    .oboardinggrid p{
        font-weight: 300;
        font-size: 13px;
        width: 75%;
    }
    
    
    .specialized{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-right: 0px;
        width: 80%;
    }
    .specializedimg img{
        height: 220px;
    }

    .specialized span{
        font-size: 20px;
        font-weight: 700;
        color: red;
        margin-bottom: 20px;
    }
    
    .specialized p{
        font-weight: 300;
        font-size: 13px;
        width: 75%;
    }
}


@media screen and (max-width: 385px) {
    .basictraining{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0px 0px;
    }
    
    .basictraining span{
        font-size: 20px;
        color: red;
        font-weight: 700;
        margin-bottom: 20px;
        position: relative;
        left: -40px;
    }
    
    .basictraining p{
        font-weight: 300;
        width: 60%;
        font-size: 13px;
    }
    
    .basictrainimg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 20px;
    }
    
    .basictrainimg img{
        height: 180px;
    }
    
    .bstoption{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .bstoption span{
        font-size: 25px;
        font-weight: 700;
        color: red;
        margin-bottom: 90px;
    }
    
    .ontarioclass{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .classgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 60px;
    }
    
    .classgrid span1{
        font-size: 16px;
        font-weight: 700;
        color: red;
        margin-bottom: 30px;
    }
    
    .classgrid p{
        font-weight: 300;
        width: 70%;
        font-size: 14px;
    }
    
    .classimg img{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        height: 180px;
    }
    
    .classgrid a{
        text-decoration: none;
        color: #fff;
        background-color: red;
        padding: 10px ;
        font-weight: 600;
        font-size: 11px;
        text-transform: capitalize;
    }
    
    .onlineclass{
        display: flex;
        justify-content: center;
        align-items:flex-start;
        flex-direction: column;
        position: relative;
        left: 50px;
    }
    
    .onlineclass span1{
        font-size: 16px;
        font-weight: 700;
        color: red;
        margin-bottom: 30px;
    }
    
    .onlineclass p{
        font-weight: 300;
        font-size: 12px;
        width: 75%;
    }
    
    .onlineclass a{
        text-decoration: none;
        color: #fff;
        background-color: red;
        padding: 13px ;
        font-weight: 600;
        font-size: 12px;
        text-transform: capitalize;
    }
    .onlineimg img{
        height: 180px;
    }
    
    .firstaidimg img{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        height: 180px;
    }
    
    .firstaidgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 60px;
    }
    
    .firstaidgrid span{
        font-size: 25px;
        font-weight: 700;
        color: red;
    }
    
    .firstaidgrid p{
        font-weight: 300;
        font-size: 13px;
        width: 75%;
    }
    
    
    .ministrygrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        position: relative;
        left: 50px;
    }

    .ministryimg img{
        height: 180px;
    }
    
    .ministrygrid span{
        font-size: 25px;
        font-weight: 700;
        color: red;
    }
    
    .ministrygrid p{
        font-weight: 300;
        margin-top: 30px;
        font-size: 13px;
        width: 85%;
    }
    
    .oboardinggrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 73%;
    }
    
    .oboardinggrid span{
        font-size: 20px;
        font-weight: 700;
        color: red;
        margin-bottom: 20px;
    }
    
    .oboardingimg{
        margin-right: 0px;
    }
    
    .oboardingimg img{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        height: 180px;
    }
    
    .oboardinggrid p{
        font-weight: 300;
        font-size: 13px;
        width: 75%;
    }
    
    
    .specialized{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-right: 0px;
        width: 80%;
    }
    .specializedimg img{
        height: 180px;
    }

    .specialized span{
        font-size: 20px;
        font-weight: 700;
        color: red;
        margin-bottom: 20px;
    }
    
    .specialized p{
        font-weight: 300;
        font-size: 13px;
        width: 75%;
    }
}