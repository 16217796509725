.home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}

.heading {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  margin-left: 20px;
  /* margin:0px 40px; */
}

.heading h3 {
  font-size: 50px;
  color: red;
}

.heading p {
  width: 80%;
  /* font-size: 15px; */
}

.photo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-left: 80px;
  /* margin-top: 40px; */
}

.photo img {
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.headbtn {
  background-color: red;
  padding: 10px;
  color: #fff;
  text-decoration: none;
}

.headbtn:hover {
  background-color: #000;
}

.about-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #dfdddd;
}

.teampic {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 80%;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}

.team-expert {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  width: 80%;
}

.team-expert p1 {
  font-size: 40px;
  color: red;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}

.team-expert span1 {
  margin: 40px 0px;
  font-weight: 300;
}

.teampic img {
  width: 80%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.btn{
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn a {
  padding: 15px 30px;
  margin: 5px 0px;
  background-color: red;
  text-decoration: none;
  color: white;
  font-size: 15px;
  font-weight: 600;
  margin-right: 20px;
  text-transform: uppercase;
}

.btn a:hover {
  background-color: black;
}

.service {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 50px 0px;
}

.service-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.service-1 p{
  width: 60%;
}

.service-1 p1 {
  font-size: 40px;
  color: red;
  font-weight: 600;
}

.service-1 span2 {
  font-weight: 300;
  margin: 30px 0px;
}

.service-1 span3 {
  font-weight: 300;
  /* margin: 30px 0px; */
}

.service-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  width: 100%;
}

.add {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  height: 500px;
  margin: 10px 20px;
  border-radius: 2px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.addimg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.addimg img {
  width: 100%;
  height: 300px;
}

.addgrid {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 150px;
  padding: 10px;
}

.addgrid span1 {
  color: red;
  font-weight: 500;
}

.addgrid p {
  font-weight: 300;
}

.addgrid a {
  text-decoration: none;
  color: white;
  background-color: red;
  padding: 10px 20px;
  font-weight: 500;
  text-transform: capitalize;
}

.addgrid a:hover {
  background-color: black;
}

.securitytech{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  background: url(../../Assets/image/breezy.jpeg);
}

.containersecurity{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000ac;
  height: 500px;
}

.rowsec{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  width: 100%;
  gap: 20px;
  padding: 0px 20px;
}

.sectech{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  background-color: #fff;
  height: 150px;
}

.sectech1 h2{
  color: red;
  font-size: 36px;
}

.about-us {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  background-color: #dfdddd;
}

.aboutsec {
  display: flex;
  justify-content: center;
  align-items: center;
}

.newway1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

.newway2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
}

.newway {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 80%;
}

.newway1 p {
  font-size: 40px;
  color: red;
  font-weight: 700;
}

.newway1 span {
  text-align: justify;
}

.newway1 a {
  text-decoration: none;
  color: white;
  background-color: red;
  padding: 15px 40px;
  font-weight: 700;
  text-transform: uppercase;
}

.newway1 a:hover {
  background-color: black;
}

.newway-para {
  margin: 0px 0px 20px 0px;
  text-align: justify;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: flex-start;
  width: 80%;
  gap: 20px;
}

.buttons1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid black;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 80px;
  width: 80px;
}

.buttons1 img {
  height: 50px;
}

.buttons1 span {
  color: #000;
  font-size: 12px;
  font-weight: 800;
}

.buttons a:hover {
  border: 1px solid red;
}

.homecontact{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 20px 0px;
}

.homecimg{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

.homecimg img{
  width: 100%;
}

.homecomtoday{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  padding: 20px;
}

.homecomtoday span{
  font-size: 44px;
  font-weight: 600;
}

.homecomtoday span1{
  color: red;
}

.homecomtoday a{
  background-color: black;
  color: white;
  text-decoration: none;
  padding: 10px;
}

.homecomtoday a:hover{
  background-color: red;
}

@media screen and (max-width: 1300px) {
  .home-1{
    flex-direction: column;
  }
  .photo{
    margin: 10px;
  }
  .heading{
    justify-content: center;
    align-items: center;
  }
  .heading h3{
    text-align: center;
  }
  .service-2{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1030px) {
  .about-1{
    flex-direction: column;
    padding: 30px 0px;
  }
  .team-expert{
    justify-content: center;
    align-items: center;
  }
  .teampic{
    margin: 0px 0px 20px 0px;
  }
  .aboutsec{
    flex-direction: column;
  }
  .newway2{
    align-items: center;
    margin: 20px 0px 0px 0px;
  }
  .homecimg img{
    width: 90%;
  }
}

@media screen and (max-width: 925px) {
  .rowsec{
    grid-template-columns: repeat(2, 1fr);
  }
  .securitytech{
    height: 600px;
  }
  .containersecurity{
    height: 600px;
  }
  .homecontact{
    flex-direction: column;
  }
}

@media screen and (max-width: 815px) {
  .add{
    justify-content: flex-start;
  }
}

@media screen and (max-width: 616px) {
  .rowsec{
    grid-template-columns: repeat(1, 1fr);
  }
  .securitytech{
    height: 1200px;
  }
  .containersecurity{
    height: 1200px;
  }
  .btn{
    flex-direction: column;
  }
  .service-2{
    grid-template-columns: repeat(1, 1fr);
  }
  .service-1 p{
    width: 80%;
  }
}

@media screen and (max-width: 460px) {
  .buttons{
    grid-template-columns: repeat(2, 1fr);
  }
}