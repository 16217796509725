.history {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.history-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.aboutus1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
}

.aboutus1 span {
  color: red;
  font-weight: 800;
  font-size: 60px;
}

.aboutus2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 0px 10px;
}

.aboutus2 p {
  font-weight: 600;
  font-size: 22px;
  color: grey;
}

.history-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../Assets/image/security.jpg);
  width: 100%;
  background-size: cover;
  height: 500px;
}

.historygrid {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px 20px;
}

.historygrid2 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  padding: 0px 20px;
}

.historygrid1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px 20px;
}

.historygrid span {
  font-size: 45px;
  color: white;
  font-weight: 800;
}

.historygrid2 span {
  text-align: center;
  width: 50%;
  font-size: 45px;
  color: white;
  font-weight: 800;
}

.historygrid p {
  font-size: 24px;
  width: 50%;
  color: white;
}

.historygrid2 p {
  font-size: 24px;
  width: 50%;
  color: white;
}
.historygrid1 p {
  font-size: 24px;
  width: 50%;
  color: white;
}

.history-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../Assets/image/vission.jpg);
  width: 100%;
  background-size: cover;
  height: 500px;
}

.history-4 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../Assets/image/mission.jpg);
  width: 100%;
  background-size: cover;
  height: 500px;
}

.whyus {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.whycontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.whyrow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.whyspan {
  font-size: 40px;
}

.whyspan span {
  color: red;
}

.whylist {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  width: 90%;
  gap: 20px;
  padding: 20px 0px;
}

.whycontent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border: 2px solid red;
  border-radius: 30px;
  padding: 10px;
  height: 350px;
}

.listimg {
  height: 150px;
}

.listimg img {
  height: 120px;
}

@media screen and (max-width: 1190px) {
  .whylist{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 850px) {
  .whylist{
    grid-template-columns: repeat(1, 1fr);
  }
  .history-1 {
    flex-direction: column;
  }
  .historygrid1 {
    align-items: center;
  }
  .historygrid {
    align-items: center;
  }
  .historygrid2 {
    align-items: center;
  }
  .historygrid p {
    width: 80%;
    background-color: #19064f;
    padding: 20px;
  }
  .historygrid2 p {
    width: 80%;
    background-color: #122537;
    padding: 20px;
  }
  .historygrid1 p {
    width: 80%;
  }
}

@media screen and (max-width: 575px) {
  .historygrid p, .historygrid1 p, .historygrid2 p{
    font-size: 20px;
  }
}

@media screen and (max-width: 401px) {
  .whycontent{
    height: 400px;
  }
  .history-3, .history-4{
    height: 600px;
  }
}