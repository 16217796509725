.contactgrid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0px;
}

.contactgrid1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  place-items: center;
  margin: 20px 0px;
}

.contactbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.contactline {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  height: 5px;
  width: 100%;
}

.aboutcontact {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 80%;
  padding: 15px;
}

.aboutcontact span {
  color: red;
  font-weight: 600;
  font-size: 30px;
  margin-left: 15px;
}

.aboutcontact p {
  font-weight: 300;
  margin-left: 15px;
}

.aboutcontact p1 {
  font-weight: 700;
  margin-left: 15px;
  margin-bottom: 20px;
}

.aboutcontact img {
  height: 16px;
}

.aboutbtns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.aboutbtns a {
  text-decoration: none;
  font-size: 14px;
  padding: 8px 20px;
  background-color: red;
  color: white;
  font-weight: 400;
  margin: 10px 15px;
}

.contactform{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 20px 0px;
}

.formimg{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    height: 600px;
}

.formimg img{
    height: 600px;
    width: 100%;
}

.formm{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
}

.conhead span{
    font-size: 40px;
    font-weight: 500;
}

.conform{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.conform input{
    height: 40px;
    width: 80%;
    margin: 10px 0px;
    padding: 0px 0px 0px 10px;
}

.conform button{
    background-color: red;
    border: none;
    padding: 10px;
    font-size: 16px;
    color: white;
    cursor: pointer;
}

.conform button:hover{
  background-color: #000;
}


@media screen and (max-width: 900px) {
  .contactgrid1{
    grid-template-columns: repeat(1, 1fr);
  }
  .contactbox{
    margin: 10px 0px;
  }
  .contactform{
    flex-direction: column;
  }
  .formm{
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .aboutbtns{
    grid-template-columns: repeat(1, 1fr);
  }
}