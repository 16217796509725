.parking {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.parking-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  background-color: black;
}

.parking2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  margin: 0px 0px 0px 10px;
}

.parking1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0px 0px 0px 10px;
}

.parking1 span {
  font-size: 40px;
  color: red;
  font-weight: 700;
}

.parking1 span1 {
  font-size: 40px;
  color: white;
  font-weight: 700;
}

.parking1 p {
  width: 80%;
  color: white;
}

.parkingimg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

.parkingimg img {
  width: 100%;
}

/* --- */

.service-1 span {
  font-size: 60px;
  color: red;
  font-weight: 600;
}

.service-1 span1 {
  color: black;
}

.add1 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  height: 600px;
  margin: 10px 20px;
  border-radius: 2px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media screen and (max-width: 900px) {
  .add1 {
    height: 650px;
  }
}

@media screen and (max-width: 765px) {
  .add1 {
    height: 700px;
  }
  .parkingimg{
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .add1 {
    height: 750px;
  }
}

@media screen and (max-width: 616px) {
  .add1 {
    height: 700px;
  }
}
