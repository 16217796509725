.faq{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 20px 0px;
    background-color: #dfdddd;
}

.faq-left{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.faq-right{
    display: flex;
    justify-content: center;
    align-items: center;
}

.faq-heading{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.faq-right span{
    font-size: 60px;
    font-weight: 500;
}

.faq-right p{
    font-size: 20px;
}

.faq-wrapper{
    width: 80%;
}

.faq-accordion{
    background-color: #dfdddd;
    margin-bottom: 5px;
    padding: 10px 20px;
    margin: 30px 0px;
    border-radius: 10px;
    border: 1px solid black;
}

.faq-title{
    color: var(--second-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.faq-title h2{
    font-size: 20px;
    margin: 10px 0px;
}

.faq-title span{
    font-size: 20px;
    font-weight: bold;
}

.content{
    color: var(--second-color);
    max-height: 0;
    
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
    font-size: 18px;
}

.content.show{
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
    font-size: 18px;
}

@media screen and (max-width: 1250px) {
    .faq{
        flex-direction: column;
    }
    .faq-right{
        width: 90%;
    }
    .faq-heading{
        align-items: center;
    }
    .faq-right span{
        font-size: 45px;
    }
}

@media screen and (max-width: 670px) {
    .faq-img img{
        width: 90%;
    }
}