.working{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.working-1{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
    background-color: #f7f7f7;
    width: 100%;
}

.workingimg{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-direction: column;
}

.workingimg img{
    height: 450px;
}

.workingtext{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-direction: column;
    margin-right: 50px;
}

.workingtext1{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.workingtext span{
    font-size: 40px;
    color: red;
    font-weight: 800;
}

.workingtext p{
    font-weight: 300;
}

.working-2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 600px;
}

.imageline{
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex: 1; */
    flex-direction: column;
}

.workimage{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.textline{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.emptyline{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: red;
    height: 180px;
    width: 6px;
    margin-right: 30px;
    border-radius: 4px;
}

.textgrid{
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.textgrid span{
    font-size: 25px;
    color: red;
    font-weight: 800;
}

.retailmalls{
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex: 1; */
    flex-direction: column;
}

.retailtext{
    display: flex;
    justify-content: center;
    align-items: center;
}

.retailtext span{
    font-size: 35px;
    color: red;
    font-weight: 800;
}

.retailicons{
  display: grid;
  grid-template-columns: repeat(2, 1fr) ;
  place-items: center;
}

.retailicongrid{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:20px 60px;
}

.retailicongrid img{
    height: 50px;
}

.retailicongrid p{
    font-size: 18px;
    font-weight: 300;
    margin-left: 10px;
}

.working-3{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 700px;
}

.criteria{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 60%;
}

.criteria span{
    font-size: 40px;
    color: red;
    font-weight: 800;
}

.criteriagrid{
  display: grid;
  grid-template-columns: repeat(4, 1fr) ;
  place-items: center;
}

.castgrid{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 40px;
}

.castgrid span{
    margin-top: 5px;
    font-weight: 700;
}

.castgrid img{
    height: 120px;
}

.working-4{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #f7f7f7;
    height: 700px;
}

.committedtext{
    display: flex;
    justify-content: center;
    align-items: center;
}

.committedtext1{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.committedtext1 span{
    font-size: 50px;
    color: red;
    font-weight: 800;
}

.committedtext1 p{
    font-weight: 300;
}

.committedimg{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 80px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.working-5{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 700px;
}

.successimg{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.successtext{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
}

.successtext1{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.successtext1 span{
    font-size: 50px;
    color: red;
    font-weight: 800;
}

.successtext1 p{
    font-weight: 300;
}

.working-6{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 650px;
    background-color: #f7f7f7;
}

.employeetesti{
    display: flex;
    justify-content: center;
    align-items: center;
}

.employeetesti span{
    font-size: 50px;
    font-weight: 800;
    color: red;
}

.employeetestimg{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.employeetestimg img{
    margin: 0px 20px;
}

@media screen and (max-width: 1485px) {
    .working-1{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 700px;
        background-color: #f7f7f7;
        width: 100%;
    }
    
    .workingimg{
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        flex-direction: column;
    }
    
    .workingimg img{
        height: 350px;
    }
    
    .workingtext{
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        flex-direction: column;
        margin-right: 0px;
    }
    
    .workingtext1{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .workingtext span{
        font-size: 35px;
        color: red;
        font-weight: 800;
    }
    
    .workingtext p{
        font-weight: 300;
        width: 90%;
    }
    .working-6{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 650px;
        background-color: #f7f7f7;
    }
    
    .employeetesti{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .employeetesti span{
        font-size: 50px;
        font-weight: 800;
        color: red;
    }
    
    .employeetestimg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
    }
    
    .employeetestimg img{
        margin: 0px 20px;
        height: 350px;
    }
}

@media screen and (max-width: 1400px) {
    .working-4{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: #f7f7f7;
        height: 700px;
    }
    
    .committedtext{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .committedtext1{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .committedtext1 span{
        font-size: 40px;
        color: red;
        font-weight: 800;
    }
    
    .committedtext1 p{
        font-size: 14px;
        font-weight: 300;
    }
    
    .committedimg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 80px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .committedimg img{
        height: 350px;
    }
    
    .working-5{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 700px;
    }
    
    .successimg{
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .successimg img{
        height: 350px;
    }
    
    .successtext{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 50px;
    }
    
    .successtext1{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .successtext1 span{
        font-size: 40px;
        color: red;
        font-weight: 800;
    }
    
    .successtext1 p{
        font-size: 14px;
        font-weight: 300;
    }
    .working-3{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 700px;
    }
    
    .criteria{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 60%;
    }
    
    .criteria span{
        font-size: 35px;
        color: red;
        font-weight: 800;
    }
    
    .criteriagrid{
      display: grid;
      grid-template-columns: repeat(4, 1fr) ;
      place-items: center;
    }
    
    .castgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin: 40px;
    }
    
    .castgrid span{
        font-size: 14px;
        margin-top: 5px;
        font-weight: 700;
    }
    
    .castgrid img{
        height: 100px;
    }
}

@media screen and (max-width: 1310px) {
    .working-1{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 700px;
        background-color: #f7f7f7;
        width: 100%;
    }
    
    .workingimg{
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        flex-direction: column;
    }
    
    .workingimg img{
        height: 300px;
    }
    
    .workingtext{
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        flex-direction: column;
        margin-right: 0px;
    }
    
    .workingtext1{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .workingtext span{
        font-size: 30px;
        color: red;
        font-weight: 800;
    }
    
    .workingtext p{
        font-weight: 300;
        font-size: 14px;
    }
    .working-6{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 650px;
        background-color: #f7f7f7;
    }
    
    .employeetesti{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .employeetesti span{
        font-size: 40px;
        font-weight: 800;
        color: red;
    }
    
    .employeetestimg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
    }
    
    .employeetestimg img{
        margin: 0px 20px;
        height: 300px;
    }
    .working-2{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 600px;
    }
    
    .imageline{
        display: flex;
        justify-content: center;
        align-items: center;
        /* flex: 1; */
        flex-direction: column;
    }
    
    .workimage{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .workimage img{
        height: 170px;
    }
    
    .textline{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    
    .emptyline{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: red;
        height: 160px;
        width: 6px;
        position: relative;
        right: 37px;
        border-radius: 4px;
    }
    
    .textgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    
    .textgrid span{
        font-size: 20px;
        color: red;
        font-weight: 800;
    }
    
    .retailmalls{
        display: flex;
        justify-content: center;
        align-items: center;
        /* flex: 1; */
        flex-direction: column;
    }
    
    .retailtext{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .retailtext span{
        font-size: 30px;
        color: red;
        font-weight: 800;
    }
    
    .retailicons{
      display: grid;
      grid-template-columns: repeat(2, 1fr) ;
      place-items: center;
    }
    
    .retailicongrid{
        display: flex;
        justify-content: center;
        align-items: center;
        margin:20px 60px;
    }
    
    .retailicongrid img{
        height: 40px;
    }
    
    .retailicongrid p{
        font-size: 16px;
        font-weight: 300;
        margin-left: 10px;
    }

    .working-4{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: #f7f7f7;
        height: 700px;
    }
    
    .committedtext{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .committedtext1{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .committedtext1 span{
        font-size: 35px;
        color: red;
        font-weight: 800;
    }
    
    .committedtext1 p{
        font-size: 12px;
        font-weight: 300;
    }
    
    .committedimg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 80px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .committedimg img{
        height: 300px;
    }
    
    .working-5{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 700px;
    }
    
    .successimg{
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .successimg img{
        height: 300px;
    }
    
    .successtext{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 50px;
    }
    
    .successtext1{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .successtext1 span{
        font-size: 35px;
        color: red;
        font-weight: 800;
    }
    
    .successtext1 p{
        font-size: 12px;
        font-weight: 300;
    }
    .working-3{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 700px;
    }
    
    .criteria{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 60%;
    }
    
    .criteria span{
        font-size: 35px;
        color: red;
        font-weight: 800;
    }
    
    .criteriagrid{
      display: grid;
      grid-template-columns: repeat(4, 1fr) ;
      place-items: center;
    }
    
    .castgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin: 40px;
    }
    
    .castgrid span{
        font-size: 14px;
        margin-top: 5px;
        font-weight: 700;
    }
    
    .castgrid img{
        height: 100px;
    }
}

@media screen and (max-width: 1150px) {
    .working-3{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 1000px;
    }
    
    .criteria{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 60%;
    }
    
    .criteria span{
        font-size: 35px;
        color: red;
        font-weight: 800;
    }
    
    .criteriagrid{
      display: grid;
      grid-template-columns: repeat(2, 1fr) ;
      place-items: center;
    }
    
    .castgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin: 40px;
    }
    
    .castgrid span{
        font-size: 14px;
        margin-top: 5px;
        font-weight: 700;
    }
    
    .castgrid img{
        height: 100px;
    }
    .working-2{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 850px;
    }
    
    .imageline{
        display: flex;
        justify-content: center;
        align-items: center;
        /* flex: 1; */
        flex-direction: column;
    }
    
    .workimage{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .workimage img{
        height: 170px;
    }
    
    .textline{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    
    .emptyline{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: red;
        height: 160px;
        width: 6px;
        position: relative;
        right: 37px;
        border-radius: 4px;
    }
    
    .textgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    
    .textgrid span{
        font-size: 20px;
        color: red;
        font-weight: 800;
    }
    
    .retailmalls{
        display: flex;
        justify-content: center;
        align-items: center;
        /* flex: 1; */
        flex-direction: column;
    }
    
    .retailtext{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .retailtext span{
        font-size: 30px;
        color: red;
        font-weight: 800;
    }
    
    .retailicons{
      display: grid;
      grid-template-columns: repeat(2, 1fr) ;
      place-items: center;
    }
    
    .retailicongrid{
        display: flex;
        justify-content: center;
        align-items: center;
        margin:20px 60px;
    }
    
    .retailicongrid img{
        height: 40px;
    }
    
    .retailicongrid p{
        font-size: 16px;
        font-weight: 300;
        margin-left: 10px;
    }
    .working-5{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 700px;
    }
    .working-1{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 700px;
        background-color: #f7f7f7;
        width: 100%;
    }
    .working-4{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        background-color: #f7f7f7;
        height: 700px;
    }
    .employeetestimg{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 50px;
    }
    .working-6{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 790px;
        background-color: #f7f7f7;
    }
}

@media screen and (max-width: 625px) {
    .working-3{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 1000px;
    }
    
    .criteria{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 60%;
    }
    
    .criteria span{
        font-size: 30px;
        color: red;
        font-weight: 800;
    }
    
    .criteriagrid{
      display: grid;
      grid-template-columns: repeat(2, 1fr) ;
      place-items: center;
    }
    
    .castgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin: 40px;
    }
    
    .castgrid span{
        font-size: 11px;
        margin-top: 5px;
        font-weight: 700;
    }
    
    .castgrid img{
        height: 80px;
    }
    .working-2{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 850px;
    }
    
    .imageline{
        display: flex;
        justify-content: center;
        align-items: center;
        /* flex: 1; */
        flex-direction: column;
    }
    
    .workimage{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .workimage img{
        height: 140px;
    }
    
    .textline{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    
    .emptyline{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: red;
        height: 120px;
        width: 6px;
        position: relative;
        right: -10px;
        border-radius: 4px;
    }
    
    .textgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    
    .textgrid span{
        font-size: 20px;
        color: red;
        font-weight: 800;
    }
    
    .retailmalls{
        display: flex;
        justify-content: center;
        align-items: center;
        /* flex: 1; */
        flex-direction: column;
    }
    
    .retailtext{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .retailtext span{
        font-size: 25px;
        color: red;
        font-weight: 800;
    }
    
    .retailicons{
      display: grid;
      grid-template-columns: repeat(2, 1fr) ;
      place-items: center;
    }
    
    .retailicongrid{
        display: flex;
        justify-content: center;
        align-items: center;
        margin:20px 60px;
    }
    
    .retailicongrid img{
        height: 35px;
    }
    
    .retailicongrid p{
        font-size: 14px;
        font-weight: 300;
        margin-left: 10px;
    }
    .working-5{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 700px;
    }
    .successimg{
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .successimg img{
        height: 250px;
    }
    
    .successtext{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
    }
    
    .successtext1{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .successtext1 span{
        font-size: 35px;
        color: red;
        font-weight: 800;
    }
    
    .successtext1 p{
        font-size: 12px;
        font-weight: 300;
    }
    .working-1{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 700px;
        background-color: #f7f7f7;
        width: 100%;
    }
    .workingimg{
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        flex-direction: column;
    }
    
    .workingimg img{
        height: 250px;
    }
    
    .workingtext{
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        flex-direction: column;
        margin-right: 0px;
    }
    
    .workingtext1{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .workingtext span{
        font-size: 25px;
        color: red;
        font-weight: 800;
    }
    
    .workingtext p{
        font-weight: 300;
        font-size: 12px;
    }
    .working-4{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        background-color: #f7f7f7;
        height: 700px;
    }
    .committedtext{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .committedtext1{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .committedtext1 span{
        font-size: 35px;
        color: red;
        font-weight: 800;
    }
    
    .committedtext1 p{
        font-size: 12px;
        font-weight: 300;
    }
    
    .committedimg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .committedimg img{
        height: 250px;
    }
    .employeetestimg{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 50px;
    }
    .working-6{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 790px;
        background-color: #f7f7f7;
    }
    .employeetesti{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .employeetesti span{
        font-size: 35px;
        font-weight: 800;
        color: red;
    }
    
    .employeetestimg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
    }
    
    .employeetestimg img{
        margin: 10px 0px;
        height: 250px;
    }
}

@media screen and (max-width: 540px) {
    .working-3{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 1700px;
    }
    
    .criteria{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 60%;
    }
    
    .criteria span{
        font-size: 25px;
        color: red;
        font-weight: 800;
    }

    .criteria p{
        font-size: 14px;
    }
    
    .criteriagrid{
      display: grid;
      grid-template-columns: repeat(1, 1fr) ;
      place-items: center;
    }
    
    .castgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin: 40px;
    }
    
    .castgrid span{
        font-size: 11px;
        margin-top: 5px;
        font-weight: 700;
    }
    
    .castgrid img{
        height: 80px;
    }
    .working-2{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 850px;
    }
    
    .imageline{
        display: flex;
        justify-content: center;
        align-items: center;
        /* flex: 1; */
        flex-direction: column;
    }
    
    .workimage{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .workimage img{
        height: 110px;
    }
    
    .textline{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    
    .emptyline{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: red;
        height: 100px;
        width: 6px;
        position: relative;
        right: -10px;
        border-radius: 4px;
    }
    
    .textgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    
    .textgrid span{
        font-size: 15px;
        color: red;
        font-weight: 800;
    }
    
    .retailmalls{
        display: flex;
        justify-content: center;
        align-items: center;
        /* flex: 1; */
        flex-direction: column;
    }
    
    .retailtext{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .retailtext span{
        font-size: 20px;
        color: red;
        font-weight: 800;
    }
    
    .retailicons{
      display: grid;
      grid-template-columns: repeat(1, 1fr) ;
      place-items: center;
    }
    
    .retailicongrid{
        display: flex;
        justify-content: center;
        align-items: center;
        margin:20px 60px;
    }
    
    .retailicongrid img{
        height: 35px;
    }
    
    .retailicongrid p{
        font-size: 14px;
        font-weight: 300;
        margin-left: 10px;
    }
    .working-5{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 700px;
    }
    .successimg{
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .successimg img{
        height: 200px;
    }
    
    .successtext{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
    }
    
    .successtext1{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .successtext1 span{
        font-size: 28px;
        color: red;
        font-weight: 800;
    }
    
    .successtext1 p{
        font-size: 12px;
        width: 100%;
        font-weight: 300;
    }
    .working-1{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 700px;
        background-color: #f7f7f7;
        width: 100%;
    }
    .workingimg{
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        flex-direction: column;
    }
    
    .workingimg img{
        height: 200px;
    }
    
    .workingtext{
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        flex-direction: column;
        width: 65%;
    }
    
    .workingtext1{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .workingtext span{
        font-size: 25px;
        color: red;
        font-weight: 800;
    }
    
    .workingtext p{
        font-weight: 300;
        font-size: 12px;
    }
    .working-4{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        background-color: #f7f7f7;
        height: 700px;
    }
    .committedtext{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .committedtext1{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .committedtext1 span{
        font-size: 35px;
        color: red;
        font-weight: 800;
    }
    
    .committedtext1 p{
        font-size: 12px;
        font-weight: 300;
    }
    
    .committedimg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .committedimg img{
        height: 250px;
    }
    .employeetestimg{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 50px;
    }
    .working-6{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 700px;
        background-color: #f7f7f7;
    }
    .employeetesti{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .employeetesti span{
        font-size: 30px;
        font-weight: 800;
        color: red;
    }
    
    .employeetestimg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
    }
    
    .employeetestimg img{
        margin: 10px 0px;
        height: 200px;
    }
}

@media screen and (max-width: 385px) {
    .working-3{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 1700px;
    }
    
    .criteria{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 60%;
    }
    
    .criteria span{
        font-size: 25px;
        color: red;
        font-weight: 800;
    }

    .criteria p{
        font-size: 14px;
    }
    
    .criteriagrid{
      display: grid;
      grid-template-columns: repeat(1, 1fr) ;
      place-items: center;
    }
    
    .castgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin: 40px;
    }
    
    .castgrid span{
        font-size: 11px;
        margin-top: 5px;
        font-weight: 700;
    }
    
    .castgrid img{
        height: 80px;
    }
    .working-2{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 850px;
    }
    
    .imageline{
        display: flex;
        justify-content: center;
        align-items: center;
        /* flex: 1; */
        flex-direction: column;
    }
    
    .workimage{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .workimage img{
        height: 90px;
    }
    
    .textline{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    
    .emptyline{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: red;
        height: 80px;
        width: 4px;
        position: relative;
        right: -13px;
        border-radius: 4px;
    }
    
    .textgrid{
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    
    .textgrid span{
        font-size: 13px;
        color: red;
        font-weight: 800;
    }
    
    .retailmalls{
        display: flex;
        justify-content: center;
        align-items: center;
        /* flex: 1; */
        flex-direction: column;
    }
    
    .retailtext{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .retailtext span{
        font-size: 16px;
        color: red;
        font-weight: 800;
    }
    
    .retailicons{
      display: grid;
      grid-template-columns: repeat(1, 1fr) ;
      place-items: center;
    }
    
    .retailicongrid{
        display: flex;
        justify-content: center;
        align-items: center;
        margin:20px 60px;
    }
    
    .retailicongrid img{
        height: 35px;
    }
    
    .retailicongrid p{
        font-size: 14px;
        font-weight: 300;
        margin-left: 10px;
    }
    .working-5{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 700px;
    }
    .successimg{
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .successimg img{
        height: 180px;
    }
    
    .successtext{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
    }
    
    .successtext1{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .successtext1 span{
        font-size: 28px;
        color: red;
        font-weight: 800;
    }
    
    .successtext1 p{
        font-size: 12px;
        width: 100%;
        font-weight: 300;
    }
    .working-1{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 700px;
        background-color: #f7f7f7;
        width: 100%;
    }
    .workingimg{
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        flex-direction: column;
    }
    
    .workingimg img{
        height: 180px;
    }
    
    .workingtext{
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        flex-direction: column;
        width: 65%;
    }
    
    .workingtext1{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .workingtext span{
        font-size: 25px;
        color: red;
        font-weight: 800;
    }
    
    .workingtext p{
        font-weight: 300;
        font-size: 12px;
    }
    .working-4{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        background-color: #f7f7f7;
        height: 700px;
    }
    .committedtext{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
    }
    
    .committedtext1{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .committedtext1 span{
        font-size: 25px;
        color: red;
        font-weight: 800;
    }
    
    .committedtext1 p{
        font-size: 12px;
        font-weight: 300;
    }
    
    .committedimg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .committedimg img{
        height: 180px;
    }
    .employeetestimg{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 50px;
    }
    .working-6{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 700px;
        background-color: #f7f7f7;
    }
    .employeetesti{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .employeetesti span{
        font-size: 30px;
        font-weight: 800;
        color: red;
    }
    
    .employeetestimg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
    }
    
    .employeetestimg img{
        margin: 10px 0px;
        height: 180px;
    }
}