/* BackToTopButton.css */
.back-to-top-button {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: red;
    color: var(--font-color);
    cursor: pointer;
    height: 50px;
    width: 50px;
    padding: 15px;
    border-radius: 50%;
    place-items: center;
}

.back-to-top-button:hover{
    background-color: #000;
}

.i{
    position: relative;
}
