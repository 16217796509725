.navbar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0px;
}

.nav-logo img {
  height: 60px;
}

.nav-links ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  list-style: none;
}

.nav-links a {
  display: inline-block;
  position: relative;
  color: red;
  text-decoration: none;
  margin: 0px 20px;
  font-size: 20px;
  font-weight: 500;
  /* border: 1px solid rgba(128, 128, 128, 0.311); */
  padding: 5px 10px;
  border-radius: 5px;
}

.nav-links a:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: red;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.nav-links a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.navbar-smallscreen {
  display: none;
}

.navbar-menu {
  font-size: 32px;
  cursor: pointer;
}

.navbar-smallscreen_overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: red;
  color: var(--font-color);
  transition: 0.5s ease;
  flex-direction: column;
  z-index: 5;
}

.navbar-smallscreen_overlay .overlay__close {
  font-size: 27px;
  color: var(--font-color);
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 20px;
}

.nav-smallscreen-input {
  margin-top: 20px;
}

.nav-small-input {
  width: 500px;
  position: relative;
}

.nav-small-input input {
  margin: 0;
  padding: 0px 0px 0px 20px;
  height: 50px;
  width: 100%;
  background: none;
  border: 2px solid var(--btn-color);
  color: var(--btn-color);
}

.nav-small-input button {
  position: absolute;
  top: 0;
  right: -20px;
  z-index: 2;
  top: 2px;
  border: none;
  background: none;
  color: red;
  font-size: 18px;
  height: 50px;
  cursor: pointer;
}

.navbar-smallscreen_links {
  list-style: none;
}

.navbar-smallscreen_links li {
  margin: 30px 0px;
}

.navbar-smallscreen_links a {
  margin: 1rem;
  cursor: pointer;
  color: white;
  font-size: 2rem;
  text-align: center;
  text-decoration: none;
  user-select: none;
}

.navbar-smallscreen_links a:hover {
  color: var(--font-base);
}

.nav-line {
  height: 2px;
  width: 90%;
  background-color: var(--font-color);
  margin-bottom: 30px;
}

.navbar-login-signup {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.navbar-login-signup a {
  text-decoration: none;
  margin: 0px 20px;
  color: var(--font);
  font-size: 20px;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--btn-color);
}

.navbar-login-signup a:hover {
  background-color: #40513bc6;
}

/* Dropdown Button */
.dropbtn {
  display: inline-block;
  position: relative;
  color: red;
  text-decoration: none;
  margin: 0px 20px;
  font-size: 20px;
  font-weight: 500;
  /* border: 1px solid rgba(128, 128, 128, 0.311); */
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  background: none;
}

.dropbtn:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: red;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.dropbtn:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content1 {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content1 a {
  color: black;
  padding: 5px;
  font-size: 12px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}

.dropdown-content1 a:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropdown-content1 {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #fff;
  color: red;
}

@media screen and (max-width: 1050px) {
  .navbar {
    justify-content: space-between;
    margin: 0px 20px;
  }

  .nav-links {
    display: none;
  }

  .navbar-smallscreen {
    display: flex;
  }
}

@media screen and (max-width: 300px) {
  .navbar {
    overflow-x: hidden;
  }
}
